{
  "title": "Self Service",
  "buttons": {
    "go_back": "back",
    "go_forward": "Next",
    "back": "Back",
    "back_button_arial_label": "Move back to previous page",
    "see_more": "See more",
    "submit": "Submit",
    "cancel": "Cancel",
    "download": "Download",
    "see_details": "See details",
    "see_more_details": "See more details",
    "back_to_main_page": "Back to main page",
    "go_to_delivery": "Continue to delivery",
    "continue": "Continue",
    "previous": "Go back",
    "order": "Order",
    "edit_vehicle": "Change vehicle",
    "save": "Save",
    "edit": "Edit",
    "remove_vehicle": "Remove vehicle",
    "register_new": "Register new",
    "close": "Close",
    "add_to_register": "Choose the vehicle you want to remove",
    "remove_from_list": "Remove from list",
    "complete": "Complete",
    "save_edits": "Save changes",
    "remove": "Remove",
    "export_passings_list": "Export passings list",
    "understand": "I understand",
    "search": "Search"
  },

  "validation": {
    "only_string": "Can only contain characters",
    "no_special_characters": "Cannot contain any special characters except for dash, space and numbers",
    "exclamation_no_special_characters": "Cannot contain any special characters except for dash, space, exclamation mark and numbers",
    "must_have_one_char": "Must contain at least one character",
    "must_have_less_than_two_char": "Cannot contain more than two characters",
    "too_many_chars": "Too many characters",
    "cannot_be_negative_value": "Cannot contain negative values",
    "only_numbers": "Can only contain numbers",
    "578_obe_number_not_in_use": "OBE numbers starting with 578 are no longer valid",
    "only_positive_numbers": "Can only contain positive numbers",
    "year": "Must be a four digit year",
    "contains_sixteen_numbers": "Must contain 16 numbers",
    "email_format": "Email needs to be in email format with @",
    "confirm_email_format": "Confirmation email needs to be identical",
    "phone_number": "The number is invalid",
    "address": "Address can contain both letters and numbers",
    "no_numbers": "Cannot contain numbers",
    "registration_number": "Must be filled out - Max 10 characters",
    "date_format": "Date is invalid",
    "postal_city_only_characters": "Can only contain letters and spaces",
    "postal_code": "This postal code doesn't exist"
  },

  "titles": {
    "overview": "Overview",
    "choose_customers": "Customer profile",
    "vehicles_and_tag": "My vehicles and tags",
    "invoices": "Invoices",
    "invoice": "Invoice",
    "passings": "Passings",
    "agreements": "Agreements",
    "home": "Home",
    "help": "Help",
    "cases": "Cases"
  },

  "profile_menu": {
    "profile": "Settings",
    "customers": "Profiles",
    "language": "Language",
    "languages": {
      "norwegian": "Norwegian",
      "english": "English"
    },
    "contact_us": "Contact us",
    "logout": "Log out"
  },

  "datepicker": {
    "start_date": "Start Date",
    "end_date": "End Date",
    "show_period": "Show period",
    "period_labels": {
      "thirty_days": "30 days",
      "custom_defined": "Custom"
    },
    "error_custom_max_one": "Max allowed to select {{numberMonths}} month",
    "error_custom_max_other": "Max allowed to select {{numberMonths}} months"
  },

  "pagination": {
    "page": "Page",
    "of": "of",
    "first_page": "First page",
    "previous_page": "Previous",
    "next_page": "Next",
    "last_page": "Last page"
  },

  "agreement_status": {
    "New": "New",
    "Active": "Active",
    "Inactive": "Inactive",
    "Frozen": "Frozen",
    "AwaitingApproval": "Awaiting approval",
    "ApprovedAwaitingShipping": "Awaiting shipping",
    "Rejected": "Rejected",
    "Cancelled": "Cancelled"
  },

  "form_file_input": {
    "error_loading_attachment": "Error loading file",
    "file_size_limit": "This file is too large to upload. The maximum supported file size is {{fileSizeLimit}}MB.",
    "upload_file": "Upload file",
    "upload_in_progress": "Uploading attachment is in progress. Try again later. ",
    "compression_failed": "Compression of image failed. Try again with a smaller file, or a different file type.",
    "compressed_still_above_limit": "Tried to compress image, but the result was still above the limit of {{fileSizeLimit}}MB."
  },

  "home": {
    "title": "Home",
    "welcome": "Welcome!",
    "overview": "Overview",
    "agreements": "Agreements",
    "passings": "Passings",
    "invoices": "Invoices",
    "make_changes": "Make changes",
    "new_vehicle": "New vehicle",
    "remove_a_vehicle": "Remove vehicle",
    "move_obe": "Move obe"
  },

  "agreements": {
    "agreement": "Agreement",
    "agreements": "Agreements",
    "introduction": "Below you will find an overview of your agreements. When you select an agreement, you can see the cars to which the agreement applies. You will receive an invoice for each agreement you have.",
    "click_here_faq": "Click here to read frequently asked questions about agreements",
    "overview": {
      "title": "Agreement overview",
      "information": "Click on an agreement from the list below to see information about which cars the agreement applies to. You can search for registration-, agreement- and tag numbers.",
      "list_info": "Click on one of the agreement for more information",
      "balance": "Sum (NOK)",
      "search": "Search",
      "click_row_for_more": "Click the row for more details about the agreement to display"
    },
    "make_changes": {
      "title": "Make changes",
      "add_vehicle": "Add a vehicle",
      "transfer_tag": "Transfer tag",
      "remove_vehicle": "Remove vehicle",
      "order_empty_tag": "Order empty tag",
      "register_empty_tag": "Register empty tag"
    },
    "obe_monthly_rent": "Toll tag subscription per month",
    "order": "Order",
    "agreement_status": "Agreement status",
    "create_agreement": "Create agreement",
    "agreement_type": "Agreement type",
    "subtitle_1": "Here you can create a new agreement that must be manually accepted by FUS before it becomes active.",
    "subtitle_2": "Agreements that await approval can be found in the agreement list.",
    "agreement_name": "Agreement name",
    "name": "Name",
    "created_at": "Created at",
    "last_changed": "Last changed",
    "total_vehicles": "Total vehicles",
    "no_unpaid_invoices": "You have no unpaid invoices",
    "unpaid_invoices_prefix": "You have ",
    "unpaid_invoices_one": "{{count}} unpaid invoice (click here to view it)",
    "unpaid_invoices_other": "{{count}} unpaid invoices (click here to view them)",
    "no_agreement": "No available agreement",
    "no_agreements": "No available agreements",
    "change_agreement_name": "Change agreement name",
    "change_agreement_description": "To change the agreement name, enter a wanted agreement name and press submit.",
    "agreement_details": {
      "balance": "Balance",
      "vehicles": "Vehicles",
      "search": "Search for agreement, vehicle or tag",
      "no_vehichles": "No available vehicles"
    },
    "remove_a_vehicle": "Remove a vehicle",
    "transfer_a_tag": "Transfer a tag",
    "choices_for_vehicles_and_tags": "Choices for vehicles and tags",
    "get_started": "Get started",
    "do_you_need_tag": "Do you need a tag on your vehicle?",
    "register_new_vehicle": "Register new vehicle",
    "order_goods": "Order goods",
    "go_to_order_empty_tag": "Go to order empty tag",
    "download_documentation": "Documentation",
    "download_documentation_arial_label": "Click the button to download vehicle documentation",
    "exclude_inactive_agreements": {
      "button_active": "Exclude inactive",
      "button_inactive": "Include inactive",
      "loading": "Loading content"
    }
  },

  "bank_reference": {
    "page_title": "Approve access",
    "helper_text_header": "You hereby grant {{Sparebank}} access to process information regarding your:",
    "consent_1": "Road toll charges and ferry trips",
    "consent_2": "Payments and balance",
    "consent_3": "Toll charging agreement and vehicles",
    "reference_id": "Bank reference",
    "button_accept": "Approve",
    "button_reject": "Reject",
    "success_msg": "Your response has been saved",
    "error_msg": "Something went wrong, try again",
    "current_status": "Current status: ",
    "accepted": "Accepted",
    "rejected": "Rejected",
    "cancelled": "Cancelled",
    "not_approved": "Not approved",
    "missing_params_feedback_p1": "Could not find a bank reference, if you still have access from the SB1 bank and would like to remove it please contact customer support.",
    "missing_customer_profile_p1": "This page is only available if you have a valid account.",
    "missing_customer_profile_p2": "Make sure you are logged into a user with an account, or create a customer profile first."
  },

  "select_agreement": {
    "agreement_invoice": "How should the tag/vehicle be invoiced?",
    "existing_agreement_option": "On an existing invoice",
    "new_agreement_option": "Create new invoice reference",
    "select_existing_agreement": "Choose an existing agreement / invoice reference",
    "select_new_agreement": "Insert name for new agreement / invoice reference",
    "agreement_invoice_reference": "Agreement / Invoice reference"
  },

  "vehicles": {
    "vehicle_details": "Vehicle details",
    "vehicle": "Vehicle",
    "nationality": "Nationality",
    "registration_number": "Registration number",
    "brand": "Brand",
    "model": "Model",
    "color": "Color",
    "classification": "Classification",
    "search": "Search",
    "weight": "Weight",
    "size": "Size",
    "heavy": "Heavy",
    "regular": "Regular",
    "euClass": "EU code",
    "fuelType": "Fuel type",
    "co2": "Co2",
    "size_choice": {
      "under": "Up to 3500 kg",
      "over": "Over 3500 kg"
    },
    "chargeable": "Chargeable",
    "chargable_choices": {
      "yes": "Yes",
      "no": "No"
    },
    "documentation": "Documentation",
    "documentation_info": "As the vehicle is not registered in Norway, you must upload documentation that the information provided is correct, e.g. vehicle card. Allowed file formats are .doc, .docx, .pdf, .png and .jpeg",
    "foreign_vehicle_documentation": "Documentation for foreign vehicle",
    "registered": "Registered",
    "confirm_info": "I confirm that all vehicle info is correct.",
    "add_vehicle": "Add vehicle",
    "no_vehicle": "No available information about vehicle",
    "optional": "Optional",
    "production_year": "Production year",
    "is_not_owner": "Please check that the vehicle information is correct",
    "business_is_not_owner": "Please check that the vehicle information is correct",
    "vehicle_not_found": "The vehicle with this registration number could not be found",
    "no_attached_vehicle": "No attached vehicle",
    "vehicle_pricing_warning": "Please note that choosing incorrect values can result in an incorrect price per passing.",
    "errors": {
      "co2_error": "Co2 field needs to be a value between 0 and 99",
      "classification_error": "Classification field is required",
      "fuelType_error": "Fuel type field is required",
      "euClass_error": "EU code field is required",
      "size_error_p1": "Incorrect size based on classification and/or EU code selected.",
      "size_error_p2": "You must either change classification, EU code, or weight class."
    },
    "eucode_missing_message": "Information about EU-code is missing. Please input the correct EU-code. You can find this information in your vehicle's registration.",
    "isRegistered": "This vehicle already has an agreement. By completing the registration, you get an agreement on the vehicle, and the previous agreement is automatically terminated",
    "available": "This vehicle is available"
  },

  "invoices": {
    "unpaid_invoices": " unpaid invoices",
    "page_details": "On this page you will find information about your invoices. Click invoice details for more information, or to download invoice as a pdf.",
    "filtering": {
      "title": "Select unpaid or paid invoices",
      "details_part_1": "Choose to see unpaid or paid invoices.",
      "details_part_2": "It might take a couple of days before payment status is updated. Search by invoice number or KID to find a specific invoice.",
      "unpaid_invoices_button": "Unpaid",
      "unpaid_btn_aria_label": "Click to show unpaid invoices",
      "paid_invoices_button": "Paid",
      "paid_btn_aria_label": "Click to show paid invoices",
      "remittance_button": "Refunds",
      "remittance_btn_aria_label": "Click to show refunds",
      "period_title": "Select period"
    },
    "search": {
      "title": "Search invoice number",
      "placeholder": "Search"
    },
    "invoice_details_button": "Invoice details",
    "invoice_details_button_aria_label": "Clikk for more invoice details",
    "invoice_details_download_button": "Download",
    "download_as_csv": "Download invoices as CSV",
    "no_invoice": "No available invoices",
    "invoices": "Invoices",
    "invoice": "Invoice details",
    "invoice_number": "Invoice number",
    "invoice_info": "Invoice information",
    "invoice_status": "Status",
    "invoice_agreement": "Agreement",
    "expiration_date": "Expiration date",
    "due_date": "Due date",
    "kr": "Kr.",
    "balance": "Balance",
    "timespan": "Timespan",
    "view_passings": "View passings",
    "view_invoice_info": "View invoice details and passings",
    "no_available_invoice_details": "No available information about invoice",
    "no_available_invoice_details_pop_up": "Invoice missing data to be presented. Contact customer support.",
    "invoice_date": "Invoice date",
    "amount": "Amount ({{currency}})",
    "kid": "KID",
    "invoice_lines": "The invoice applies",
    "quantity": "Quantity",
    "description": "Description",
    "no_invoice_lines": "No invoice lines available",
    "total": "Total",
    "contact_debt_collector": "Please contact debt collector for details",
    "history": "Invoice history",
    "history_info": "The overview below shows invoices and any payment reminders.",
    "types": {
      "table_header": "Invoice type",
      "debt_collection": "Debt collection",
      "debt_collection_notice": "Payment reminder",
      "invoice": "Invoice",
      "credit_note_distributed": "Credit note distributed",
      "missing_invoice_type": "Missing invoice type: {{invoiceType}}"
    },
    "number": "Invoice number",
    "download_version_button": "Download invoice",
    "download_version_button_arial_label": "Click to download invoice version",
    "no_history_found": "No invoice history found",
    "error_fetching_invoice_versions": "An error occured when fetching invoice history",
    "error_downloading_invoice_version": "An error occured when downloading invoice version",
    "history_table_caption": "A table that contains each invoice version that has been sent to the customer, as well as a button per row for downloading the spesific versions",
    "archived_disclaimer": {
      "title": "Note",
      "text": "The passing invoice lines for this invoice have been archived.",
      "button_text": "Download archived invoice"
    }
  },

  "orders": {
    "type": {
      "obe": "OBE",
      "obe_holder": "OBE Holder",
      "blank_obe": "Blank OBE"
    },
    "info_section_titles": {
      "order_date": "Order date",
      "product_type": "Product type",
      "quantity": "Quantity",
      "reg_number": "Vehicle registration number",
      "reg_country": "Vehicle registration country"
    },
    "pending_orders": "Your pending orders",
    "load_more": "Load more",
    "no_pending_orders": "No pending orders"
  },

  "legacy_invoices": {
    "note": "Note",
    "note_info_first": "Because of the migration to a new system, your old invoices",
    "note_info_second": "paid prior to September 1st 2021",
    "note_info_third": "have been moved to the overview of legacy invoices.",
    "view_legacy_invoices": "View archived invoices",
    "legacy_invoices": "Archived invoices",
    "legacy_invoices_info": "View and download invoices paid before 01.09.2021.",
    "legacy_invoice_date": "Billing date",
    "no_legacy_invoices": "No invoices available",
    "no_more_legacy_invoices": "No further invoices available",
    "return_to_invoice_overview": "Back to invoice overview",
    "return_button_aria_label": "Click to return to the invoice overview",
    "infobox": "Invoices paid before 01.09.2021 are archived."
  },

  "passings": {
    "passings": "Passings",
    "download_as_csv": "Download all passings as CSV by given period",
    "info": "Passing information",
    "checkpoint": "Checkpoint",
    "time": "Time",
    "price": "Price",
    "no_passing": "No available information found",
    "no_available_passings": "No passings information available",
    "total_results": "total results",
    "export_caution": "CAUTION. Downloading large amounts of passings affects performance and may take a couple of minutes. Please use reasonably.",
    "fill_filters": "Select period and a vehicle"
  },

  "tag": {
    "tag": "Tag",
    "battery_status": "Battery status",
    "tag_status": "Tag status",
    "tag_number": "Tag number",
    "agreement_number": "Agreement number",
    "expiration_date": "Expiration date",
    "number_of_tags": "Number of tags",
    "order_box": "Or choose to order an entire box (120 empty tags)",
    "no_tag": "No available tags",
    "tag_details": {
      "vehicle_details": "Vehicle details",
      "transfer_tag": "Transfer this tag",
      "edit_vehicle": "Edit vehicle",
      "remove_vehicle": "Remove vehicle",
      "tag_details": "Tag details"
    },
    "dialog": {
      "titles": {
        "success": "Success"
      },
      "subtitles": {
        "success": "Your changes have been saved. The vehicle info will be updated once we have approved the changes"
      }
    },
    "complete_order": "Complete order",
    "completed_order": "Thank you for your order!",
    "max": "Maximum of",
    "registered": "This tag is still active on an existing account. Press next if you want the tag to be attached to a new vehicle. The existing agreement will then be inactivated.",
    "available": "This tag is available",
    "loading": "loading...",
    "error_fetching_obe_details": "An error occurred while setting up download button for vehicle documentation. Try again later."
  },

  "order_tag": {
    "order_tag": "Order tag",
    "tag_order_completed": "Your order is registered and the tag will be sent to you by mail.",
    "tag_order_warning_title": "Your tag is not active just yet.",
    "tag_order_warning_subtitle": "When the order is processed and it appears in your agreement list, you can drive through all AUTOPASS toll stations and receive discount on your passings!",
    "proceed_order_tag_first": "A toll tag subscription will be charged monthly, according to the current price when the tag is registered to a vehicle.",
    "proceed_order_tag_second": "Discounts are valid from when the tag is registered, usually 1-5 days after the order date.",
    "terms_and_conditions": "General and special agreement conditions",
    "accept_terms_and_conditions": "I have read and accept the terms and conditions"
  },


  "transfer_tag": {
    "bulk": {
      "documentation": "Documentation",
      "progress": {
        "initial": {
          "title": "Transfer tag by file",
          "description": "Here you can upload a file for transfering more tags at the same time. We refer to the following link for documentation:",
          "button": "Validate"
        },
        "valid": {
          "title": "Valid file",
          "description": "tags were found, ready for transfer.",
          "button": "Confirm"
        },
        "invalid": {
          "title": "Invalid file",
          "description": "Check the documentation. There were errors in the file on line(s):"
        },
        "confirmed": {
          "title": "Tags transferred",
          "description": "tags were transferred"
        },
        "error": {
          "title": "Error",
          "description": "Something went wrong, check the documentation, try again. Otherwise contact customer support."
        }
      },
      "start_over": "Start over"
    },
    "transfer_tag": "Transfer tag",
    "transfer_this_tag": "Transfer this tag to a new vehicle",
    "has_existing_tag": "Do you already have a tag?",
    "has_existing_tag_info": "Move your tag to a new vehicle if you are going to use the existing tag in a new vehicle.",
    "choose_tag_or_vehicle": "Choose tag / vehicle",
    "transfer_complete": "Transfer complete",
    "transfer_is_registered": "Transfer for a tag has been registered",
    "transfer_is_registered_info": "Information about the transfer",
    "transfer_is_registered_info_2": "The change take effect when it is registered in the national AutoPASS-system. This may take up to 24 hours",
    "belongs_to": "Belongs today to this vehicle",
    "removed_from": "The vehicle the tag is removed from",
    "new_vehicle": "New vehicle for the tag"
  },

  "empty_tag": {
    "empty_tag": "Empty tags",
    "empty_tags_amount_lowercase": "empty tags",
    "one_empty_tag_lowercase": "empty tag",
    "one_box": "1 box of empty tags",
    "order_empty_tags": "Order empty tags",
    "order_of_empty_tags": "Order of empty tags",
    "empty_tags_description": "When ordering empty tags the tags you receive will not be connected to a vehicle. These tags can be registered to vehicles through the Self Service portal.",
    "maximum_empty_tags": "Maximum 120 empty tags",
    "order_boxholder": "Order a box",
    "empty_tags_order_completed": "Your order is registered and tag(s) will be sent to you by mail.",
    "payment_deposit_info": "A toll tag subscription will be charged monthly, according to the current price when the tag is registered to a vehicle."
  },

  "register_blank_tag": {
    "register_blank_tag": "Register empty tag",
    "enter_tag_number": "Enter empty tag number",
    "scan_tag": "Scan tag",
    "barcode_scanner": "Barcode scanner",
    "registration_complete": "Registration completed",
    "registration_subtitle": "Registration of vehicle(s) on empty tags is completed",
    "registration_info": "The agreement is valid from tomorrow. When your agreement is valid, you will be able to get a discount on your toll passes"
  },

  "unregister_vehicle": {
    "remove_vehicle": "Remove vehicle",
    "choose_vehicle": "Choose a vehicle you want to remove",
    "tag_options_title": "What do you want to do with the tag?",
    "keep_and_reuse": "Reuse tag for later use",
    "discard_tag": "Discard tag",
    "removed_vehicle": "Vehicle is removed",
    "disclaimer": "Disclaimer",
    "disclaimer_info": "Toll passings can take up to 24 hours to register in our systems. Your balance might be updated after removing a vehicle.",
    "discounts_info_subtitle": "Benefits and discounts are removed",
    "discounts_info_info": "on tags that are not registered on any vehicles",
    "all_tags_removed_subtitle": "If all belonging tags to an agreement is removed",
    "all_tags_removed_info": ", the agreement will be terminated.",
    "deposit_info": "If you choose to not reuse the toll tag, we encourage you to send it in return to Postbox 393, 0103 Oslo.",
    "deposit_subtitle": "Any remaining deposit will be refunded on the next invoice.",
    "throw_away_tags": "Tags without the purpose of reuse are thrown as electronic waste",
    "tag_option_title": "Tag option choice"
  },

  "address": {
    "delivery_address_title": "Delivery address",
    "delivery_address": "Address for delivery of tags",
    "another_address": "Custom address",
    "first_address": "Address line 1",
    "second_address": "Address line 2 - optional",
    "postal_code": "Postal code",
    "postal_city": "Postal city",
    "make_standard_delivery_address": "Make this my standard delivery address",
    "standard_delivery_address_info": "This will not change your invoice address.",
    "no_address": "No available address found",
    "country": "Country"
  },

  "status": {
    "new": "New",
    "shipped": "Shipped",
    "frozen": "Frozen",
    "lost": "Lost",
    "active": "Active",
    "pending": "Pending",
    "awaiting": "Awaiting",
    "inactive": "Inactive",
    "on_hold": "On hold for creation",
    "terminated": "Terminated",
    "awaiting_shipping": "Approved - waiting for shipping"
  },

  "customers": {
    "customer_number": "Customer number",
    "about_customers_title": "Read more",
    "about_customer_profiles_part_1": "Below you can see the profiles connected to your method of login (BankID or username).",
    "about_customer_profiles_part_2": "In the overview you can find both personal profiles and business profiles you have access to.",
    "about_customer_profiles_part_3": "After selecting a customer profile, you can click the icon in the upper right corner to return to this page.",
    "warning_darkmode": "You currently have darkmode enabled. For a better user experience we recommend that you use lightmode.",
    "sparebank1_infobox": {
      "title": "SpareBank 1",
      "body": "Select the private customer profile you wish to continue with, or create a new one if you do not already have one."
    },
    "customer_profile_cards": {
      "private_title": "Personal customer profiles",
      "company_title": "Business profiles",
      "no_associated_business": "No associated companies",
      "search": "Search profiles"
    },
    "add_action_cards": {
      "title": "Other customer profiles",
      "add_customer_profile_title": "Other customer profiles",
      "create_new_customer": "Create a new customer profile",
      "create_new_customer_info": "Create a new personal customer profile.",
      "how_to_add_existing_company_profile_title": "How to add an existing business profile?",
      "how_to_create_new_company_profile_title": "How to add a new business profile?",
      "how_to_sub_text": "Click to read how at"
    },
    "infobox": {
      "title": "The first time you log in, you need to connect your existing customer profile to your login (username or BankID).",
      "body": "Find your customer number on an invoice from us and choose '{{button}}'. Contact us if you need help finding your customer number.",
      "button": "Activate",
      "button_aria_label": "Click the button to add an existing customer relationship"
    },
    "error_message_fetching_customers": "Failed to retrieve customers.",
    "error_message_fetching_users": "Failed to retrieve or create user.",
    "activate_privilege_dialog": {
      "title": "Activate access",
      "ok_btn": "Activate access",
      "description": "You have received an invitation to access a customer!",
      "customer_number_desc": "You should have received a customer number from the customer that is granting you access. Enter the customer number below.",
      "info_about_closing": "If you close this dialog you can open it again by clicking the link in the e-mail.",
      "customer_number_error": "Customer number is required",
      "customer_number": "Customer number",
      "authorization_error": "An authorization error occured. You cannot access this customer.",
      "not_found_error": "The requested access has already been used or has expired",
      "not_using_bankid": "Norwegian customers need be logged in with bankID to activate access",
      "grant_token_expired": "The grant access link has expired",
      "wrong_customer_number": "Customer number does not match",
      "bankid_on_foreign_customer": "Foreign customers cannot be activated when logged in with BankID",
      "birth_date_no_match": "Birth date on the customer agreement does not match your BankID"
    },
    "recover_customer": {
      "customer_number": "Customer number",
      "registration_number": "Registration number",
      "button_header": "Activate existing customer profile",
      "button_subtext": "Connect your existing profiles against your customer number and login method.",
      "page_title": "Access to existing customer",
      "submit_button_text": "Log in",
      "cancel_button_text": "Cancel",
      "helper_text_header": "When you log in for the first time, you have to enter your customer number and a registration number.",
      "customer_number_helper_text": "You can find your customer number in a previous invoice",
      "registration_number_helper_text": "Write the car's registration number. If you have several cars, enter the registration number of one of them",
      "registration_number_invalid_text": "Invalid value",
      "error_message": {
        "not_found": "Could not find an existing user profile. Beware that the registration number must be attached to the customer with the given customer number.",
        "no_access": "We need more information to connect to your user profile. Please contact our customer service."
      },
      "confirm_recover_button_text": "Next"
    }
  },

  "invoice_status": {
    "pending": "Payment requested",
    "internal_debt_collection": "Internal debt collection sent ",
    "internal_debt_pending": "Pending internal debt collection",
    "external_debt_collection": "At external debt collector",
    "external_debt_pending": "Pending external debt collector",
    "debt_collection": "Debt collection",
    "paid": "Paid",
    "payment_requested": "Payment requested",
    "payment_failed": "Payment failed",
    "cancelled": "Cancelled",
    "credited": "Credited",
    "payment_revoked": "Payment revoked",
    "payment_reminder_sent": "Notice of debt collection sent",
    "payment_reminder_pending": "Pending notice of debt collection",
    "closed_by_debt_collector": "Closed by debt collector",
    "credit_rejected": "Credit rejected",
    "credit_pending": "Credit pending",
    "credit_note_new": "New credit note",
    "credit_note_cancelled": "Credit note cancelled",
    "credit_note_distribution_pending": "Credit note pending",
    "credit_note_distributed": "Credit note distributed",
    "credit_note_unknown_payment": "Unknown credit note payment",
    "soft_reminder_sent": "Payment reminder sent"
  },

  "profile": {
    "create_new_customer": "Create a new customer profile",
    "customer_profile": "Customer profile",
    "contact_info": "My contact information",
    "organization_name": "Organization name",
    "personal_info": "Personal information",
    "first_name": "First name",
    "last_name": "Last name",
    "birth_date": "Birth date",
    "email": "Email",
    "billing_email": "Invoicing via email",
    "billing_email_description": "You have chosen to accept the distribution of invoices via e-mail. This can be changed by contacting customer service.",
    "edit_email": "Edit your email address",
    "new_email": "New email address",
    "confirm_new_email": "Confirm new email address",
    "current_email": "Current email address",
    "mobile": "Mobile",
    "telephone": "Telephone",
    "phone_number_info": "Norwegian phone numbers should consist of eight numbers (prefix not included).",
    "addresses": "My addresses",
    "customer_address": "Customer address",
    "standard_billing_address": "Standard billing address",
    "edit_telephone": "Edit phone number",
    "standard_invoice_address": "Standard invoice address",
    "standard_delivery_address": "Standard delivery address",
    "add_billing_address": "Add a billing address",
    "add_shipping_address": "Add a delivery address",
    "edit_address": "Edit your address",
    "edit_billing_address": "Edit your billing address",
    "edit_delivery_address": "Edit your delivery address",
    "delivery_address": "Address for delivery",
    "billing_address": "Address for billing",
    "no_profile": "No profile found",
    "no_email": "No available information about email",
    "no_billing_email": "No available information about billing email",
    "no_address": "No available information about address",
    "no_telephone": "No available information about telephone number",
    "access_management": {
      "title": "Access Management",
      "no_content": "No privileges found. This is probably an error.",
      "description": "An overview over users that have access to the current customer. Access means that they can both view and edit the customer. You can grant and remove access from users. When you grant someone access they will need to activate their privilege before their user show up in the list below.",
      "search": "Search for name or email in list",
      "privilege_dialog_reason_validation": "Reason must be filled out",
      "add_privilege": {
        "button": "Grant access",
        "dialog_title": "Grant access",
        "dialog_description": "Enter email address of the user that shall receive access. The user will not appear in the access list until the user has received an e-mail and confirmed their access.",
        "dialog_ok_button": "Send invite",
        "reason": "Reason why this user get access."
      },
      "revoke_privilege": {
        "dialog_title": "Revoke access",
        "dialog_description_title": "Are you sure you want to remove access?",
        "dialog_description": "The user will be notified and will no longer be able to see or edit the customer",
        "reason": "Reason why this user get their access removed.",
        "dialog_ok_button": "Remove access"
      }
    },
    "bank_reference": {
      "title": "Access to information about passings and payments",
      "description": "Below is a list of the consents you have given to share information about passings and payments. You may at any time revoke the consent.",
      "button": "Grant access"
    }
  },

  "cases": {
    "cases": "Cases",
    "case": "Case",
    "search_placeholder": "Look up case by number",
    "status": {
      "InProgress": "In Progress",
      "New": "New",
      "Paused": "Paused",
      "Closed": "Closed"
    },
    "no_case_found": "No case found",
    "page_info": "If you have been in contact with customer service you can look up the case here by number to see its status",
    "info": "Below you can access your cases.",
    "button": "My cases"
  },

  "help": {
    "introduction": "On this page you will find information about frequently asked questions and how to get in touch with support.",
    "self_help": {
      "title": "Self help",
      "text": "To find answers to the most frequently asked questions and inquiries that support receives, we recommend checking out the Frequently Asked Questions pages.",
      "frequently_asked_questions_hyperlink_text": "Click here to read frequently asked questions"
    },
    "contact_support": {
      "title": "Contact support",
      "text": "If you do not find the answer to your question in the Frequently Asked Questions pages, please contact support. You can choose between chat or contact form.",
      "contact_page_hyperlink_text": "Click here to be redirected to the 'Contact Us' page"
    }
  },

  "error": {
    "something": "Something went wrong",
    "tag_order": "Something went wrong when ordering tag for",
    "unregister": "Something went wrong when unregister vehicle(s), this can be caused by the lack of paid deposit.",
    "register": "Something went wrong when registering tag(s)",
    "create_agreement": "Something went wrong when creating new agreement",
    "authorization": "Access denied",
    "gotomainpage": "Go to main page",
    "redirect_to_customer_profiles_page": "Redirecting to the customer profile page in {{timeoutInSec}} seconds..."
  }
}
